import React, { useRef } from 'react'
import { Link } from 'gatsby';
import styled from "styled-components";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {Section, SectionTitle, Container } from "../Section"
import ProductCardGridView from '../ProductCardGridView';

const SlickSlideCarousel = styled.div`
  margin:0;
  &:after, &:before{
    content:"";
    position:absolute;
    top:0;
    bottom:0;
    width: 120px;
    z-index: 1;
  }
  &:after{
    left:0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );

  }
  &:before{
    right:0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
  }
`
const SlickItem = styled.div`
  outline: none;
  .card{
    margin:0 10px 25px 10px;
    @media(min-width:1200px){
      margin:0 15px 25px 15px;
    }
    @media(min-width:1600px){
      margin:0 25px 25px 25px;
    }
  }
`
const ProductCarousel = ({ data }) => {
  const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      centerMode: true,
      centerPadding: '50px',
      infinite: true,
      focusOnSelect: true,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [       
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            centerPadding: '160px',
          }
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1,
            centerPadding: '60px',
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '20px',
          }
        }
        
      ]  
  };

  const sliderRef = useRef(null);
    return (
      <Section ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px" bgColor="#fff"  className="section-product">
        <Container className="container">
          <SectionTitle textAlign="center" mb="40px">Our Top-Selling Metal Buildings</SectionTitle>
        </Container>
        <SlickSlideCarousel>
          <Slider {...settings} ref={sliderRef}>
            {data.map((item, i) => (
              <SlickItem key={i}>
                <ProductCardGridView product={item.node} />
                {/* <Link to={item.node.url}>
                </Link> */}
              </SlickItem>                    
            ))}
          </Slider>
        </SlickSlideCarousel>
      </Section>
    );
}

export default ProductCarousel;